var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import BlogPostTags from "./BlogPostTags.vue";
import BlogPostContentLoading from "./BlogPostContentLoading.vue";
export default Vue.extend({
    components: {
        BlogPostTags: BlogPostTags,
        BlogPostContentLoading: BlogPostContentLoading,
    },
    apollo: {
        liveBlogPost: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query BlogPostQuery($slug: String!) {\n          liveBlogPost(slug: $slug) {\n            title\n            date\n            metaDescription\n            date\n            content\n            fullSizeImage\n            ...BlogPostTags_post\n          }\n        }\n        ", "\n      "], ["\n        query BlogPostQuery($slug: String!) {\n          liveBlogPost(slug: $slug) {\n            title\n            date\n            metaDescription\n            date\n            content\n            fullSizeImage\n            ...BlogPostTags_post\n          }\n        }\n        ", "\n      "])), BlogPostTags.options.fragments.post),
            variables: function () {
                return {
                    slug: this.$route.params.slug,
                };
            },
        },
    },
    computed: {
        breadcrumbs: function () {
            var crumbs = [{ name: this.$t("Blog"), link: "/blog" }];
            if (this.liveBlogPost) {
                crumbs.push({ name: this.liveBlogPost.title });
            }
            return crumbs;
        },
    },
});
var templateObject_1;
